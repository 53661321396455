<template>
    <div class="login-box" style="margin:30px auto;">
      <div class="card">
        <div class="card-body login-card-body">
          <div class="login-logo"><img style="width: 120px;" :src="'../images/logo.png'"/></div>
          <b-overlay :show="loader">
            <p class="login-box-msg">LOGIN PANEL</p>    
            <form v-on:keyup.enter="login">         
              <div class="input-group mb-3">
                <input type="email" class="form-control" name="mobile" v-model="form.mobile" placeholder="Mobile No" required>
                <div class="input-group-append">
                  <div class="input-group-text"><span class="fas fa-mobile"></span></div>
                </div>
                <span class="text-danger show-error" v-if="errors.mobile">{{ errors.mobile[0] }}</span>  
              </div>           
              <div class="form-group mb-3">  
                <label for="password">Password </label>              
                <input v-if="showPassword" type="text" v-model="form.password" class="form-control input-password"/>
                <input v-else type="password" class="form-control input-password" name="password" v-model="form.password" placeholder="Password" autocomplete="off" required>
                <div class="password-eye-icon" @click="toggleShow">
                  <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                </div>  
              </div>
              <div class="row pb-3">
                <div class="col-8">
                  <div class="icheck-primary">
                    <input type="checkbox" id="remember" name="remember" value="1" >
                    <label for="remember">Remember Me</label>
                  </div>
                </div>
                <div class="col-4">
                  <button type="submit" @click.prevent="login" class="btn btn-primary btn-block">Sign In</button>
                </div>
              </div>  
            </form>   
            <div class="row">
                <div class="col-6 text-right">
                  <router-link to="/auth/otp">Forgot Password ?</router-link>
                </div>
              </div>
          </b-overlay>   
        </div>
      </div>
    </div>
</template>
<script>
import config from '@/config'
export default {
  name:'Login',
  data(){
    return{
      loader: false,
      menuLoading: false,
      showPassword: false,
      form:{
        mobile:'',
        password:''
      },
      errors:[],
    }
  },
  methods:{
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    async login(){
      this.loader = true
      const response = await config.postData("/rider/login", this.form)
      this.loader = false  
      if(response.success){
        localStorage.setItem('accessToken', response.access_token);
        this.$store.dispatch('setAuthUser', response.rider)
        this.$router.push('/')
      } else if (response.status_code == 403) {
        this.$toast.error(response.message)
      } else {
        this.$toast.error({
          title: 'Error',
          message: 'Mobile or password not match',
          color: '#dc3545'
        })
      }
    }
    
  }
}
</script>
<style scoped>
  .input-mobile, .input-password {
    height: 38px !important;
  }
  .password-eye-icon {
    float: right;
    margin-top: -30px;
    margin-right: 5px;
  }
</style>